<template>
    <div class="m-3">
        <h6>{{ getTranslationByKey('ems.requested_videos_title') }}</h6>
        <table class="table table-sm small text-left">
            <thead>
            <tr>
                <th>{{ getTranslationByKey('reports.criterias.object') }}</th>
                <th>{{ getTranslationByKey('reports.card_transactions_table.table_source') }}</th>
                <th>{{ getTranslationByKey('reports.criterias.period') }}</th>
                <th>{{ getTranslationByKey('reports.table.duration') }}</th>
                <th>{{ getTranslationByKey('reports.table.loaded_Size') }}</th>
                <th>{{ getTranslationByKey('reports.table.size') }}</th>
                <th>{{ getTranslationByKey('reports.table.requested_at') }}</th>
                <th>{{ getTranslationByKey('reports.table.status') }}</th>
                <th>{{ getTranslationByKey('reports.table.action') }}</th>
            </tr>
            </thead>
            <tbody v-if="!loading">
            <RequestedVideoRow
                v-for="row in requestedVideos"
                :key="row.id"
                :rowValue="row"
                :allowDownload="allowDownload"
            />
            </tbody>
            <tbody v-else>
            <tr>
                <td colspan="9" class="text-center py-3 font-weight-600 border-bottom">
                    {{ getTranslationByKey('history.loading_data') }}
                </td>
            </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between align-items-center mt-3">
            <div></div>
            <div class="pagination-container d-flex justify-content-center align-items-center">
                <button
                    class="btn btn-sm btn-secondary mx-1"
                    :disabled="currentPage === 1"
                    @click="goToPage(currentPage - 1)"
                >
                    &laquo;
                </button>

                <button
                    v-for="page in visiblePages"
                    :key="page"
                    class="btn btn-sm mx-1"
                    :class="{ 'btn-primary': page === currentPage, 'btn-light': page !== currentPage }"
                    @click="goToPage(page)"
                >
                    {{ page }}
                </button>

                <button
                    class="btn btn-sm btn-secondary mx-1"
                    :disabled="currentPage === totalPages"
                    @click="goToPage(currentPage + 1)"
                >
                    &raquo;
                </button>
            </div>
            <div>
                <label for="rowsPerPage">{{ getTranslationByKey('reports.table.number_of_rows') }}:</label>
                <select id="rowsPerPage" class="form-select form-select-sm d-inline-block w-auto ml-2" v-model.number="itemsPerPage" @change="updateRowsPerPage">
                    <option v-for="option in rowsPerPageOptions" :key="option" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import RequestedVideoRow from './RequestedVideoRow.vue';
import { mapGetters } from 'vuex';
import api from '@/api';
import helper from '@/helper';

export default {
    components: {
        RequestedVideoRow,
    },
    props: {
        refreshListWidget: {
            type: Number
        },
    },
    watch: {
        refreshListWidget(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.goToPage(1);
            }
        }
    },
    data() {
        return {
            currentPage: 1,
            itemsPerPage: Number(localStorage.getItem('itemsPerPage')) || 10,
            rowsPerPageOptions: [10, 25, 50, 100, 500],
            requestedVideos: [],
            requestedVideoAmount: 0,
            loading: true,
        };
    },
    computed: {
        ...mapGetters([
            'getTranslationByKey',
            'user',
        ]),
        totalPages() {
            return Math.ceil(this.requestedVideoAmount / this.itemsPerPage);
        },
        visiblePages() {
            const start = Math.max(this.currentPage - 2, 1);
            const end = Math.min(this.currentPage + 2, this.totalPages);
            return Array.from({length: end - start + 1}, (_, i) => start + i);
        },
        allowMy() {
            return this.user?.permission['sm.ems.library.video.view.my'];
        },
        allowDownload() {
            return this.user?.permission['sm.ems.library.video.download']
        },
    },
    async mounted() {
        if (this.allowMy) {
            try {
                await this.loadRequestedVideos(0, this.itemsPerPage);
            } finally {
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
    },
    methods: {
        async loadRequestedVideos(offset, limit) {
            try {
                const response = await api.get('ems/evidence-request/actual/my', {
                    params: {limit, offset},
                });

                if (response && response.data) {
                    this.setRequestedVideos(response.data.data || []);
                    this.setTotalAmount(response.data.total_count || 0);
                    this.initializeWebSocket();
                }
            } catch (error) {
                this.handleErrorMixin(error);
            }
        },
        updateRowsPerPage() {
            localStorage.setItem('itemsPerPage', this.itemsPerPage);
            this.goToPage(1); // Reset to the first page when items per page changes
        },
        setRequestedVideos(data) {
            this.requestedVideos.splice(0, this.requestedVideos.length, ...data.map(row => ({
                id: row.id,
                object: row.object_title,
                source: row.requested_source,
                from: row.requested_datetime_from,
                to: row.requested_datetime_till,
                duration: helper.calculateDurationString(row.duration),
                loadedSize: helper.convertSize(row?.loaded_size),
                size: helper.convertSize(row?.size),
                percent: Math.round(row.file_ready_percent),
                nextTryAt: row.next_try_at,
                statusName: row.status_name,
                orderInQueue: row.order_in_queue,
                statusInfo: row.status_info,
                objectInfo: row.object_info,
                fileName: '',
                requestedAt: row.created_at,
                urlDownload: row.url_download,
            })));
        },
        updateRequestedVideo(data) {
            const video = this.requestedVideos.find(row => row.id === data.id);
            if (video) {
                Object.assign(video, {
                    duration: helper.calculateDurationString(data.duration),
                    loadedSize: helper.convertSize(data.loadedSize),
                    size: helper.convertSize(data.size),
                    percent: Math.round(data.percent),
                    nextTryAt: data.nextTryAt,
                    statusName: data.statusName,
                    orderInQueue: data.orderInQueue,
                    statusInfo: data.statusInfo,
                    objectInfo: data.objectInfo,
                    fileName: data.fileName,
                });
            }
        },
        setTotalAmount(amount) {
            this.requestedVideoAmount = amount;
        },

        async goToPage(page = 0) {
            if (page === 0) {
                page = 1;
            }
            if (page >= 1 && page <= this.totalPages) {
                this.loading = true;
                try {
                    this.currentPage = page;
                    this.requestedVideos = [];
                    await this.loadRequestedVideos(
                        this.itemsPerPage * (page - 1),
                        this.itemsPerPage
                    );
                } finally {
                    this.loading = false;
                }
            }
        },
        initializeWebSocket() {
            const userId = this.$store.getters.getUid;
            const channel = this.$pusher.subscribe(`private-ems-request-updated.${userId}`);
            channel.bind('ems-request-updated', (data) => {
                if (!data || typeof data !== 'object' || !data.id) {
                    return;
                }
                this.updateRequestedVideo(data);
            });
        },

    },
};
</script>

<style lang="scss" scoped>
th {
    padding-block: 10px;
}

.pagination-container .btn {
    min-width: 40px;
    text-align: center;
}
</style>
