import moment from 'moment';
import api from '../../api';
import helper from '../../helper';
import errorMixin from '../../mixins/handleErrorMixin';
export default {
    state: {
        loading: false,
        requestCompleted: false,
        guidedCurrentStep: 0,
        guidedVideoList: null,
        guidedShowProgress: true,
        guidedAvailableCameras: [],
        guidedAvailableMicrophones: [],
        // chart video list
        videoLists: [],
        videoListRetry: false,
        historyData: [],
        speedToChart: [],
    },
    actions: {
      async requestVideos({commit, state}, collectedData) {
        state.loading = true;
        let requestData = {
          object_id: collectedData.objectId,
          flow_type_name: collectedData.typeName,
          sources: collectedData.sources.join(", "),
          reason: collectedData.description,
          micId: collectedData.micId,
          from: collectedData.period.startTime,
          till: collectedData.period.endTime,
        };

        try {
          const response = await api.post("ems/evidence-request", requestData);

          if (response.status === 200) {
            commit('REQUEST_COMPLETED', true)
          }
        } catch (error) {
          commit('REQUEST_COMPLETED', false)
          errorMixin.methods.handleErrorMixin(error)
        }
      },
      // for chart
      async requestVideoList({ commit }, objectId) {
        try {
          const response = await api.post(`objects/${objectId}/request-available-media`);
          if (response.status === 200) {
            commit('GUIDED_PROGRESS_SHOW', true)
          }
        } catch (error) {
          errorMixin.methods.handleErrorMixin(error)
        }
      },
      WSReceiveVideoLists({commit, state}, objectId) {
        commit('SET_GUIDED_CURRENT_STEP', 0);
        const pusher = this._vm.pusher.pusher;
        const { getUid } = this.getters;
        const channel = pusher.subscribe(
            `private-ems-request-list-state.${getUid}.${objectId}`
        );
        channel.bind("ems-request-list-state", (data) => {
          commit('UPDATE_CURRENT_STEP', data.status);
          if (data.status === 'complete') {
            api.get(`ems/evidence-request/${data.id}/data`, {})
            .then((response) => {
              commit('SET_VIDEO_LISTS', response.data.cameras ?? [])
              commit('SET_AVAILABLE_MICROPHONES', response.data.mics ?? [])
            }).catch((error) => {
              this.handleErrorMixin(error);
            });
          }
        });
      },
    },
    mutations: {
      REQUEST_COMPLETED(state, data) {
        state.requestCompleted = data;
      },
      UPDATE_CURRENT_STEP(state, status) {
        if (state.videoListRetry) {
          state.videoListRetry = false;
        }
        switch (status) {
          case "registered":
            this.commit("SET_GUIDED_CURRENT_STEP", 1);
            break;
          case "received":
            this.commit("SET_GUIDED_CURRENT_STEP", 2);
            break;
          case "processing":
            this.commit("SET_GUIDED_CURRENT_STEP", 3);
            break;
          case "complete":
            this.commit("SET_GUIDED_CURRENT_STEP", 4);
            setTimeout(() => { this.commit("GUIDED_PROGRESS_SHOW", false) }, 1500)
            break;
          case "error":
          case "timeout":
            state.videoListRetry = true;
            this.commit("SET_GUIDED_CURRENT_STEP", 0);
            break;
          default:
            this.commit("SET_GUIDED_CURRENT_STEP", 0);
        }
      },
      GUIDED_PROGRESS_SHOW(state, status) {
        state.guidedShowProgress = status;
      },
      SET_GUIDED_CURRENT_STEP(state, currentStep) {
        state.guidedCurrentStep = currentStep;
      },
      SET_VIDEO_LISTS(state, videoLists) {
        state.videoLists = videoLists;
        state.guidedAvailableCameras = [];
       state.videoLists.forEach(c => {
          if (c.timeList.length > 0) {
            state.guidedAvailableCameras.push({id: c.id, name: c.name, visible: false})
          }

        })
        if (state.guidedAvailableCameras.length > 0) {
          state.guidedAvailableCameras[0].visible = true
        }
      },
      SET_AVAILABLE_MICROPHONES(state, microphones) {
        state.guidedAvailableMicrophones = microphones
      },
      SET_HISTORY_DATA(state, historyData) {
        state.historyData = historyData;
      },
      // SET_SPEED(state) {
      //   state.speedToChart = []
      //   const startingVideo = moment(state.videoLists[0].timeList[0].from * 1000);
      //   const lastEl = state.videoLists[state.videoLists.length - 1];
      //   let endingVideo;

      //   if (lastEl.timeList.length) {
      //     endingVideo = moment(lastEl.timeList[lastEl.timeList.length - 1].till * 1000);
      //   } else {
      //     for (let i = state.videoLists.length -1; i >= 0; i--) {
      //       const obj = state.videoLists[i];
      //       if (obj.timeList.length) {
      //         endingVideo = moment(obj.timeList[obj.timeList.length - 1].till * 1000);
      //         break;
      //       }
      //     }
      //   }

      //   state.speedToChart = state.historyData.filter(obj => {
      //     const txdt = moment(obj.txdt);
      //     return txdt.isBetween(startingVideo, endingVideo, 'second')
      //   });
      // },
    },
    getters: {
      getRequestedVideosLoading(state) {
        return state.loading;
      },
      isRequestCompleted(state) {
        return state.requestCompleted;
      },
      getGuidedProgressCurrentStep(state) {
        return state.guidedCurrentStep;
      },
      getGuidedShowProgress(state) {
        return state.guidedShowProgress;
      },
      getVideoLists(state) {
        return state.videoLists;
      },
      getGuidedAvailableCameras(state) {
        return state.guidedAvailableCameras;
      },
      getGuidedAvailableMicrophones(state) {
        return state.guidedAvailableMicrophones
      },
      getVideoListRetry(state) {
        return state.videoListRetry;
      },
      getHistoryData(state) {
        return state.historyData;
      },
      getSpeedToChart(state) {
        return state.speedToChart;
      }
    }
}